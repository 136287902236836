/* Dropify */

.dropify-wrapper {
	border: 2px dashed $border-color;
	border-radius: 7px;
	background: lighten($card-bg, 4%);

	&:hover {
		background-size: 30px 30px;
		background-image: $dropify-wrapper-hover-bg;
		-webkit-animation: stripes 2s linear infinite;
		animation: stripes 2s linear infinite;
	}

	.dropify-message {
		span.file-icon {
			&:before {
				display: inline-block;
				font: normal normal normal 24px/1 "Material Design Icons";
				font-size: inherit;
				text-rendering: auto;
				line-height: inherit;
				-webkit-font-smoothing: antialiased;
				content: '\F552';
				color: $body-color;
				font-size: 1.25rem;
			}
		}

		p {
			font-size: 0.8125rem;
			color: $body-color;
		}
	}

	.dropify-preview {
		.dropify-infos {
			background: $dropify-infos-bg;

			.dropify-infos-inner {
				.dropify-filename {
					color: $dropify-infos-inner-filename-color;
					font-size: 20px;
					font-weight: 400;
				}

				.dropify-infos-message {
					color: $dropify-infos-message;
					font-weight: 500;
				}
			}
		}

		.dropify-render {
			img {
				opacity: 0.7;
			}
		}
	}

	.dropify-clear {
		background: $dropify-clear-bg;
		border: none;
		font-weight: 700;
		color: $dropify-clear-color;
	}
}
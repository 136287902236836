/* Js-grid */
.jsgrid {

	.jsgrid-header-cell,
	.jsgrid-cell {
		background: lighten($card-bg, 4%);
		border-color: $border-color;
	}

	.jsgrid-button {
		background-image: url("../../images/sprites/jsgrid-icons.png");
	}

	.jsgrid-grid-header {
		border: 1px solid $border-color;
		border-top: 0;
	}

	.jsgrid-grid-body {
		border-color: $border-color;
	}

	.jsgrid-table {
		@extend .table;

		th {
			font-weight: initial;
			font-family: $type-1;
			border-top-color: $border-color;
		}

		td {
			border-color: $border-color;
		}

		.jsgrid-filter-row {

			input[type=text],
			select {
				@extend .form-control;
			}

			input[type=number] {
				@extend .form-control;
				padding: 0.56rem 0;
			}
		}
	}

	.jsgrid-header-sort {
		&:before {
			margin-top: 10px;
			float: right;
		}
	}

	.jsgrid-header-sort-asc {
		&:before {
			border-color: transparent transparent $border-color;
		}
	}

	.jsgrid-header-sort-desc {
		&:before {
			border-color: $border-color transparent transparent;
		}
	}

	.jsgrid-pager {
		line-height: 2;
		display: flex;
		align-items: center;
		@extend .pagination;

		.jsgrid-pager-nav-button,
		.jsgrid-pager-page {
			@extend .page-item;

			a {
				@extend .page-link;
			}
		}
	}

	.jsgrid-pager-current-page {
		padding: 0.5rem 0.75rem;
		font-weight: initial;
		line-height: 1.25;
	}
}